import * as React from 'react';
import { TextField, Button, Snackbar } from '@kmx/legos-react';
import { Select } from '@kmx/legos-react-select';
import TabBar from './TabBar';
import Tab from './Tab';
import classNames from 'classnames';
import { isValidVin } from './utils/vinValidator';
import { formatVin } from './utils/format';
import ValidatedArea from './ValidatedArea';
import { usaStates } from './utils/lookups';
import { IInstantOffersFormData } from './InstantOffersContainer';
import TabPanel from './TabPanel';

interface IInstantOffersFormProps {
    readonly?: boolean;
    hasInvalidLicensePlate: boolean;
    onSubmit: (event: any, formData: IInstantOffersFormData) => any;
}

const tabState = { PLATE: 0, VIN: 1 };

const TAB_PLATE_ID = 'LicensePlate';
const TAB_VIN_ID = 'VIN';

export default function InstantOffersForm(props: IInstantOffersFormProps) {
    const initialPlate = '',
        initialState = '',
        initialZipCode = '';

    const [vin, setVin] = React.useState('');
    const [state, setState] = React.useState(initialState);
    const [plate, setPlate] = React.useState(initialPlate);
    const [zipCode, setZipCode] = React.useState(initialZipCode);

    const [vinError, setVinError] = React.useState('');
    const [stateError, setStateError] = React.useState('');
    const [plateError, setPlateError] = React.useState('');
    const [zipCodeError, setZipCodeError] = React.useState('');
    const [showInvalidLicensePlateMessage, setShowInvalidLicensePlateMessage] = React.useState(
        props.hasInvalidLicensePlate
    );

    const [isValid, setIsValid] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState(tabState.PLATE);

    React.useEffect(() => {
        if (
            zipCode &&
            zipCode.length == 5 &&
            ((activeTab === tabState.PLATE && state && plate && /[^0-9a-zA-Z]*/.test(plate)) ||
                (activeTab === tabState.VIN && vin && vin.length == 17 && isValidVin(vin)))
        )
            setIsValid(true);
        else setIsValid(false);
    }, [vin, state, plate, zipCode, activeTab]);

    React.useEffect(() => {
        if (plate != null && plate != '' && plate.replace(/[^0-9a-zA-Z]*/, '').length <= 0)
            setPlateError('License plate must be entered');
        else setPlateError('');
    }, [plate, state]);

    React.useEffect(() => {
        if (state == null) setStateError('Please make a selection above');
        else setStateError('');
    }, [state, plate]);

    React.useEffect(() => {
        if (props.hasInvalidLicensePlate) {
            setPlateError(' ');
            setStateError(' ');
            setIsValid(false);
        }
    }, [props.hasInvalidLicensePlate]);

    const zipValid = () => {
        if (zipCode === null || zipCode.length === 0) setZipCodeError('ZIP code must be entered');
        else if (zipCode.length > 0 && zipCode.length != 5) setZipCodeError('ZIP code must be 5 digits');
        else setZipCodeError('');
    };

    const vinValid = () => {
        if (vin.length != 17) setVinError('VIN must be a 17 character Vehicle Identification Number');
        else if (!isValidVin(vin))
            setVinError(
                'VIN must be a valid Vehicle Identification Number. Please check that you have entered the VIN correctly.'
            );
        else setVinError('');
    };

    const zipInput = (
        <div
            className={classNames('input-field input-field--zip', {
                ['collapsed-input']: activeTab === tabState.PLATE,
            })}
        >
            <TextField
                label="What's your zip code?"
                onChange={(val, e) => setZipCode(val.replace(/[^0-9]/, ''))}
                value={zipCode}
                disabled={props.readonly}
                helperText={zipCodeError ? zipCodeError : 'ZIP code must be 5 digits'}
                maxLength={5}
                type="tel"
                id="ico-form-zipcode"
                onBlur={zipValid}
            />
        </div>
    );

    const plateInput = (
        <TabPanel id={TAB_PLATE_ID} active={activeTab === tabState.PLATE}>
            <div className="input-field">
                <TextField
                    label="License plate number"
                    value={plate}
                    onChange={(val, e) => setPlate(val.replace(/[^0-9a-zA-Z \\-]/, ''))}
                    maxLength={10}
                    helperText={plateError ? plateError : 'License Plate must be entered'}
                    id="ico-form-plate"
                    name="plate"
                />
            </div>
            <ValidatedArea
                className="select-input input-field collapsedInput"
                overlayIcon={true}
                invalid={stateError ? true : false}
                validationMessage=" "
            >
                <Select
                    id="ico-state-select"
                    options={usaStates}
                    value={state}
                    disabled={props.readonly}
                    onChange={(e: any) => setState(e.target.value)}
                    label="Where's your car registered?"
                />
            </ValidatedArea>
            {showInvalidLicensePlateMessage && (
                <Snackbar
                    title=""
                    className="snackbar"
                    textLabel={
                        <div>
                            Invalid license plate
                            <br />
                            <div className="snackbarSecondLineText">
                                We could not find this license plate in{' '}
                                {(usaStates.find((x) => x.value === state) as any).label}
                            </div>
                        </div>
                    }
                    acceptButtonLabel="DISMISS"
                    acceptAction={() => setShowInvalidLicensePlateMessage(false)}
                    cancelAction={() => setShowInvalidLicensePlateMessage(false)}
                    closeTimeout={5000}
                    type="warning"
                />
            )}
            {zipInput}
        </TabPanel>
    );

    const vinInput = (
        <TabPanel id={TAB_VIN_ID} active={activeTab === tabState.VIN}>
            <div className="input-field input-field--vin">
                <TextField
                    label="VIN"
                    value={vin}
                    onChange={(val, e) => {
                        setVin(formatVin(val));
                    }}
                    maxLength={17}
                    helperText={vinError ? vinError : 'VIN must be a 17 character Vehicle Identification Number'}
                    id="ico-form-vin"
                    name="vin"
                    onBlur={vinValid}
                />
            </div>
            {zipInput}
        </TabPanel>
    );

    const renderInput = () => {
        switch (activeTab) {
            case tabState.PLATE:
                return plateInput;
            case tabState.VIN:
                return vinInput;
            default:
                return plateInput;
        }
    };

    return (
        <>
            <h2 className="instant-offers__form__content__pre-heading">Sell/trade in</h2>
            <h3 className="instant-offers__form__content__heading">
                Real offers. <br /> Really fast.
            </h3>
            <TabBar className="tabBar" id="ICO: Vehicle Profile">
                <Tab
                    id={TAB_PLATE_ID}
                    onClick={() => setActiveTab(tabState.PLATE)}
                    active={activeTab === tabState.PLATE}
                    numTabs={1}
                >
                    License Plate
                </Tab>
                <Tab
                    numTabs={1}
                    id={TAB_VIN_ID}
                    onClick={() => setActiveTab(tabState.VIN)}
                    active={activeTab === tabState.VIN}
                >
                    VIN
                </Tab>
            </TabBar>
            <form
                onSubmit={(e) => {
                    let type: IInstantOffersFormData['type'];
                    if (vin && activeTab === tabState.VIN) {
                        type = 'VIN';
                    } else if (plate && activeTab === tabState.PLATE) {
                        type = 'PLATE';
                    } else {
                        // unexpected form state
                        return;
                    }

                    props.onSubmit(e, {
                        isValid: isValid,
                        type: type,
                        state: state,
                        zipCode: zipCode,
                        vin: vin,
                        plate: plate,
                    });
                }}
                className="inputs"
            >
                {renderInput()}
                <hzn-button class="submit-button" variant="cta" type="submit" disabled={!isValid || undefined}>
                    Get Started
                </hzn-button>
                <small className="instant-offers__form__content__disclaimer">
                    Most qualify. Some we&apos;ll ask to verify in-person.
                </small>
            </form>
        </>
    );
}
