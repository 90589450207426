import * as React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import EligibleOfferCountdown from './EligibleOfferCountdown';
import { formatSeperatedInteger } from './utils/format';
import { IInstantOfferDetails } from './InstantOffers';
import { buildScheduleEligibleOfferLink } from './utils/constants';

interface IEligibleOfferProps {
    offerAmount: number;
    expirationDateUtc: string;
    expirationDisplayDate: string;
    offerCode: string;
    storeId: string;
    instantOfferDetails: IInstantOfferDetails;
}

function EligibleOffer(props: IEligibleOfferProps) {
    dayjs.extend(customParseFormat);
    dayjs.extend(duration);

    const calculateDurationToExpiration = () => {
        return dayjs.duration(Math.max(0, expirationDate.diff(dayjs())));
    };

    const expirationDate = dayjs(props.expirationDisplayDate || props.expirationDateUtc)
        .hour(23)
        .minute(59)
        .second(59);
    const [durationToExpiration, setDurationToExpiration] = React.useState(calculateDurationToExpiration());

    React.useEffect(() => {
        const cleanup = () => {
            clearInterval(durationCalcInterval);
        };

        const durationCalcInterval = setInterval(() => {
            const timeToExpiration = calculateDurationToExpiration();

            setDurationToExpiration(timeToExpiration);

            if (timeToExpiration.asSeconds() <= 0) {
                cleanup();
            }
        }, 10000);

        return cleanup;
    }, []);

    const isExpired = () => {
        return durationToExpiration.asSeconds() <= 0;
    };

    const vehicleMakeAndModel = () => {
        return props.instantOfferDetails.vehicleMake && props.instantOfferDetails.vehicleModel
            ? `${props.instantOfferDetails.vehicleMake} ${props.instantOfferDetails.vehicleModel}`
            : 'vehicle';
    };

    const title = isExpired() ? (
        <h2 className="instant-offers-eligible-offer--card-title">It's time to update your offer</h2>
    ) : (
        <h2 className="instant-offers-eligible-offer--card-title">
            Your ${formatSeperatedInteger(props.offerAmount)}
            <sup>&dagger;</sup> offer on your {vehicleMakeAndModel()} expires soon
        </h2>
    );

    return durationToExpiration != null ? (
        <div className="instant-offers-eligible-offer">
            <div className="instant-offers-eligible-offer--inner-containers">
                <div className="instant-offers-eligible-offer--countdown">
                    <EligibleOfferCountdown title="Days" value={durationToExpiration.days()} />
                    <EligibleOfferCountdown title="Hours" value={durationToExpiration.hours()} />
                    <EligibleOfferCountdown title="Minutes" value={durationToExpiration.minutes()} />
                </div>
                {title}
            </div>

            <div className="instant-offers-eligible-offer--btn-container">
                {isExpired() ? (
                    <hzn-button variant="cta" onClick={() => props.instantOfferDetails.onStartNewOffer()}>
                        Get Started{' '}
                    </hzn-button>
                ) : (
                    <>
                        <hzn-button
                            variant="cta"
                            class="instant-offers-eligible-offer--primary-btn"
                            href={buildScheduleEligibleOfferLink(props.storeId, props.offerCode)}
                            target="_blank"
                            full-width
                        >
                            Set My Appointment
                        </hzn-button>
                        <hzn-button
                            variant="tertiary"
                            tone="inverse"
                            class="instant-offers-eligible-offer--secondary-btn"
                            type="button"
                            onClick={() => props.instantOfferDetails.onStartNewOffer()}
                            full-width
                        >
                            Start a new offer
                        </hzn-button>
                    </>
                )}
            </div>

            <div className="instant-offers-eligible-offer--inner-containers instant-offers-eligible-offer--supplemental-info-container">
                <p className="instant-offers-eligible-offer--supplemental-info-copy">
                    {isExpired() ? (
                        `Your offer expired on ${expirationDate.format('MM/DD/YYYY')}`
                    ) : (
                        <>
                            Valid until {expirationDate.format('MM/DD/YYYY')}
                            <span className="instant-offers-eligible-offer--supplemental-info-divider">|</span> Offer
                            Code: {props.offerCode}
                        </>
                    )}
                </p>
            </div>
        </div>
    ) : null;
}

export default EligibleOffer;
