import initTabBar from '../../../components/legos-tab-bar';
import { smoothScroll } from '../../../utilities/smoothScroll';

export const initLinkFarmInteractions = () => {

    const viewMoreButtons = document.getElementsByClassName('link-farm-tabs--view-more-button');

    function toggleViewMoreViewLess(e: any) {
        const contentId = e.target.getAttribute('data-for');
        if(contentId) {
            const thisTabContainer = document.getElementById(contentId);
            if(thisTabContainer) {
                changeExpandedState(isExpanded(thisTabContainer), thisTabContainer)
            }
        }
    }
    
    function isExpanded(thisTabContainer: HTMLElement) {
        return thisTabContainer.classList.contains('link-farm-tabs--tab-content__is-expanded');
    }
    
    function changeExpandedState(isCurrentlyExpanded: boolean, thisTabContainer: HTMLElement) {
        const thisTabsViewMoreButtonLabel = thisTabContainer.querySelector('.link-farm-tabs--view-more-button-label');
        const thisTabsViewMoreButton = thisTabContainer.querySelector('.link-farm-tabs--view-more-button');
        thisTabContainer.classList[ isCurrentlyExpanded ? 'remove' : 'add']('link-farm-tabs--tab-content__is-expanded');
        thisTabsViewMoreButton ? thisTabsViewMoreButton.setAttribute('disabled', 'true') : null;
        setTimeout(() => {
            thisTabsViewMoreButtonLabel ? thisTabsViewMoreButtonLabel.innerHTML = (isCurrentlyExpanded ? 'View More' : 'View Less') : null;
            thisTabsViewMoreButton ? thisTabsViewMoreButton.removeAttribute('disabled') : null;
        }, (isCurrentlyExpanded ? 375 : 0));

        isCurrentlyExpanded ? smoothScrollBackIntoView() : null;
    }

    function smoothScrollBackIntoView() {
        setTimeout(() => {
            const linkFarmContainer: HTMLElement | null = document.getElementById('link-farm-tabs');
            smoothScroll((linkFarmContainer as HTMLElement), -20);
        }, 700);
    }

    function attachEventListers() {
        if (viewMoreButtons) {
            for (let i = 0, loopLen = viewMoreButtons.length; i < loopLen; i++) {
                viewMoreButtons[i].addEventListener('click', (e:any) => toggleViewMoreViewLess(e));
            }
        }
    }

    initTabBar('.link-farm-tabs');
    attachEventListers();
}